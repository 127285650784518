.b1-header {
    min-height: 44px !important;
    padding: 6px 30px !important;
}

.b2-header {
    min-height: 44px !important;
    padding: 6px 30px !important;
    background-color: #9494E4 !important;
    &:hover {
        background-color: rgba(148,148,228,0.49) !important;
    }
}

.ct-header {
    [data-row="middle"] {
        .ct-container {
            max-width: 1348px;
        }
    }
}

nav[class*=menu] li[class*=current-menu-]>a {
    font-weight: 700;
}

.c-bg-hero {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 0 0 0 20px;
    }
}

.hero-buttons {
    column-gap: 15px;
}

.bg-hero-pages {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        width: 100vw;
        height: 100%;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 0 0 150px 0;
    }
}

.c-bg-yellow-left {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        width: 50vw;
        height: 500px;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 0 20px 20px 0;
    }
}

.c-bg-yellow-left2 {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        width: 50vw;
        height: 500px;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 0 20px 20px 0;
    }
}

.c-bg-yellow-left-p {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -29px;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100%;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 0 20px 20px 0;
    }
}

.c-pos {
    .gb-inside-container {
        margin-left: 0 !important;
    }
}

.c-bg-yellow-right {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        width: 50vw;
        height: 500px;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 20px 0 0 20px;
    }
}

.c-bg-yellow-right2 {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 150px;
        bottom: 52px;
        width: 50vw;
        height: 640px;
        background-color: #FFF1C9;
        z-index: -1;
        border-radius: 20px 0 0 20px;
    }
}

.c-bg-gray-right {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        width: 100vw;
        height: 100%;
        background-color: #FAFAFA;
        z-index: -1;
        border-radius: 20px 0 0 20px;
    }
}

.h-style {
    .gb-icon {
        svg {
            height: auto !important;
        }
    }
}

.h-icon-position {
    .gb-icon {
        order: 2;
    }
    .gb-headline-text {
        order: 1;
    }
}

.is-style-outline {
    a {
        padding: 1px 37px 3px 37px !important;
        border: 3px solid #F0BE30 !important;
        color: #F0BE30 !important;
        &:hover {
            border: 3px solid #FFE18C !important;
            color: #FFFFFF !important;
        }
    }
}

.c-nieuws {
    position: relative;
    display: flex;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        width: 10px;
        height: 102px;
        background: url(/wp-content/uploads/2023/07/Frame-26.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-self: center;
    }
}

.entries {
    article {
        position: relative;
        .ct-image-container {
            padding: 25px 25px 19px 25px;
            border-radius: 20px;
            margin-bottom: 0 !important;
        }
        .entry-meta {
            position: absolute;
            top: 39px;
            left: 0;
            z-index: 9;
            height: 35px;
            padding: 5px 27px 5px 27px;
            background: #F0BE30;
            border-radius: 10px;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
        .entry-title {
            margin-bottom: 0 !important;
        }
        .entry-excerpt {
            margin-bottom: 7px !important;
        }
        .entry-button {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 30px;
                height: 26px;
                background: url(/wp-content/uploads/2023/07/icon-right.png);
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-self: center;
            }
            svg {
                fill: transparent !important;
            }
        }
    }
}

.style-accordion {
    .wp-block-aab-accordion-item {
        .aagb__accordion_head {
            padding: 16px 25px 16px 17px !important;
            // .aagb__accordion_heading {
                
            // }
            .aagb__accordion_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33px;
                height: 33px;
                .aagb__icon {
                    font-size: 18px !important;
                    &:before {
                        font-size: 18px !important;
                    }
                }
            }
        }
        .aagb__accordion_body {
            padding: 13px 35px 13px 17px !important;
            p {
                font-size: 14px !important;
                line-height: 24.5px !important;
                color: #2D3651 !important;
                margin-bottom: 0 !important;
            }
        }
    }
    .wp-block-aab-accordion-item.aagb__accordion_active {
        border-width: 0 !important;
        .aagb__accordion_body {
            border-top-width: 0 !important;
        }
    }
}

.style-form {
    .gb-inside-container {
        background-color: #fff1c9;
        border-radius: 20px;
    }
    #gform_wrapper_3 {
        form {
            .gform-body {
                .gform_fields {
                    grid-column-gap: 3%;
                    .gfield {
                        label {
                            font-size: 18px !important;
                            line-height: 31.5px !important;
                            font-weight: 400 !important;
                            color: #656C81 !important;
                            margin-bottom: 0 !important;
                        }
                        legend {
                            color: transparent !important;
                            margin-bottom: 0 !important;
                        }
                    }
                    .spacer {
                        display: none;
                    }
                    #field_3_6 {
                        .gchoice_3_6_1 {
                            input {
                                width: 30px;
                                height: 30px;
                                border-radius: 10px;
                                background-color: #FFFFFF;
                            }
                            label {
                                padding-left: 16px;
                            }
                        } 
                    }
                }
            }
        }
    }
}

.gform-field-row--span-three {
    grid-row: span 3;
}

#gform_wrapper_2 {
    form {
        .gform-body {
            .gform_fields {
                grid-column-gap: 6%;
                .gfield {
                    label {
                        font-size: 14px !important;
                        line-height: 24.5px !important;
                        font-weight: 400 !important;
                        color: #656C81 !important;
                        margin-bottom: 0 !important;
                    }
                }
                #field_2_5 {
                    textarea {
                        height: 155px;
                    }
                }
            }
        }
    }
}

.gform_required_legend {
    display: none;
}

table tbody tr:first-child td:first-child {
    border-top-left-radius: 10px;
}
table tbody tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.style-table {
    table {
        border: 0 !important;
        thead {
            border-bottom: 0;
            tr {
                th {
                    font-size: 18px !important;
                    font-weight: 400 !important;
                    line-height: 31.5px !important;
                    border: 0 !important;
                    &:first-child {
                        font-weight: 700 !important;
                        color: #8CA6F8 !important;
                    }
                }
            }
        }
        tbody {
            background-color: #FFFFFF !important;
            tr {
                position: relative;
                td {
                    border: 0 !important;
                    padding: 9px 15px !important;
                    &:first-child {
                        font-weight: 700 !important;
                        color: #2D3651 !important;
                    }
                }
                &:last-child {
                    border-bottom: 0;
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 94%;
                    height: 1px;
                    background-color: #D6D8DF;
                }
            }
        }
    }
}

.ct-footer {
    [data-row*="middle"] {
        .widget-title {
            margin-bottom: 25px;
        }
    }
}

#block-8 {
    margin-top: 5px;
}

#blocksy_ct_socials-3 {
    margin-top: 30px;
    .widget-title {
        display: none;
    }
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
    }
}

// article>.entry-content>*.gb-container-df432723.alignfull {
//     .gb-inside-container {
//         width: var(--container-width);
//     }
// }

.c-over-h {
    overflow: hidden;
}

.hero-section {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        right: -170px;
        bottom: 63px;
        width: 198px;
        height: 243px;
        background: url(/wp-content/uploads/2023/07/Group-1.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.c-tarieven {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 7px;
        width: 99px;
        height: 115px;
        background: url(/wp-content/uploads/2023/07/Vector-6.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
    }
}

.hero-pages-bg {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -70px;
        right: 0;
        width: 113px;
        height: 115px;
        background: url(/wp-content/uploads/2023/07/Vector-4.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.hero-pages-bg1 {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 50px;
        width: 135px;
        height: 115px;
        background: url(/wp-content/uploads/2023/07/Vector-7.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.section-bg-p {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 107px;
        left: 0;
        width: 81px;
        height: 115px;
        background: url(/wp-content/uploads/2023/07/Vector-8.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 9;
    }
}

ul {
    padding-left: 19px !important;
}

@include break-below(lgl) {
    .c-bg-hero{
        &:before {
            width: 100%;
            border-radius: 20px !important;
        }
    }
    .hero-section {
        &:before {
            display: none;
        }
    }
    .hero-pages-bg {
        &:before {
            display: none;
        }
    }
    .hero-pages-bg1 {
        &:before {
            display: none;
        }
    }
    .c-bg-gray-right {
        &:before {
            width: 100%;
            border-radius: 20px !important;
        }
    }
    .c-bg-yellow-right2 {
        &:before {
            width: 100%;
            height: 100%;
            left: 0;
            border-radius: 20px !important;
        }
    }
    .c-bg-yellow-left2 {
        &:before {
            width: 100%;
            height: 100%;
            left: 0;
            border-radius: 20px !important;
        }
    }
    .b-direct {
        justify-content: flex-start !important;
    }
}

@include break-below(mdl) {
    .c-bg-yellow-right {
        &:before {
            height: 100%;
        }
    }
}

@include break-below(sm) {
    .c-bg-yellow-left {
        &:before {
            width: 100%;
            height: 100%;
            border-radius: 20px !important;
        }
    }
    .c-bg-yellow-right {
        &:before {
            width: 100%;
            height: 100%;
            border-radius: 20px !important;
        }
    }
    .c-nieuws {
        &:before {
            display: none !important;
        }
    }
}

@include break-below(xs) {
    .style-table {
        table {
            thead {
                tr {
                    th {
                        font-size: 13px !important;
                        float: left;
                        &:first-child {
                            width: 45%;
                        }
                        &:nth-child(2) {
                            width: 29%;
                        }
                        &:nth-child(3) {
                            width: 26%;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 11px !important;
                        line-height: 18px;
                        float: left;
                        &:first-child {
                            width: 45%;
                        }
                        &:nth-child(2) {
                            width: 29%;
                        }
                        &:nth-child(3) {
                            width: 26%;
                        }
                     }
                }
            }
        }
    }
}